<template>
<div>
  <div class="ps-section ps-home-menu-of-day" id="carte">
        <div class="ps-section__left">
          <div class="ps-section__header">
            <h3>Nos productions</h3><i class="chikery-tt3"></i>
          </div>
          <div class="ps-section__content">
            <div class="ps-block--product-menu ps-tab-root">
              <div class="ps-block__header">
                <ul class="ps-tab-list">
                  <li v-for="cat in categories" :key="cat.type" id="cat.type" :class="[active.type == cat.type ? 'active' : '']" v-on:click="active = cat"><a><i :class="cat.icon"></i>{{cat.type}}</a></li>
                </ul>
              </div>
              <div class="ps-tabs">
                <div class="ps-tab active" id="tab-menu-1">
                  <div class="row">
                    <div v-for="product in active.products" :key="product.nom" class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                      <div class="ps-product--horizontal">
                        <div class="ps-product__container">
                          <div class="ps-product__content"><p class="ps-product__title">{{product.nom}}</p>
                            <p>{{product.desc}}</p>
                          </div>
                          <div class="ps-product__price"><span>{{product.price}}</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ps-section__right bg--cover" style="background: url(img/pain/FB_IMG_1656005989735.jpg)"></div>
      </div>
</div>
</template>

<script>

export default {
  name: 'App',
  data: function(){
    return {
      categories: [
        { 
          type: "Patisserie",
          icon: "chikery-bk3",
          products : [
            {
              nom: "Buche Royal Michard",
              desc: "",
              price: "5€50"
            },
            {
              nom: "Dame Mac’",
              desc: "",
              price: "6€20"
            },
            {
              nom: "Mac’Michard",
              desc: "",
              price: "6€20"
            },
            {
              nom: "Mère Gourmande",
              desc: "",
              price: "4€50"
            },
            {
              nom: "Buches Voyage des îles",
              desc: "",
              price: "5€50"
            },
            {
              nom: "Paris-Pontoise",
              desc: "",
              price: "4€50"
            },
            {
              nom: "Miss cheese",
              desc: "",
              price: "4€50"
            },
            {
              nom: "Flan à l’ ancienne",
              desc: "",
              price: "2€80"
            }
          ]
        },
        { 
          type: "Sacheterie",
          icon: "chikery-bk4",
          products : [
            {
              nom: "​Sachet Meringuettes",
              desc: "",
              price: "3€"
            },
            {
              nom: "Sachet tuiles",
              desc: "",
              price: "6€50"
            },
            {
              nom: "Sachet minis financiers",
              desc: "",
              price: "6€50"
            },
            {
              nom: "Sachet Madeleines",
              desc: "",
              price: "4€"
            }
          ]
        },
        { 
          type: "Viennoiserie",
          icon: "chikery-bk2",
          products : [
            {
              nom: "Croissant beurre AOP",
              desc: "",
              price: "1€20"
            },
            {
              nom: "Pain au chocolat",
              desc: "",
              price: "1€30"
            },
            {
              nom: "Pain aux raisins",
              desc: "",
              price: " 1€50"
            },
            {
              nom: "Chausson aux pomme",
              desc: "",
              price: "1€80"
            },
            {
              nom: "Briochette Pontoisienne",
              desc: "",
              price: "1€20"
            },
            {
              nom: "Pain Suisse",
              desc: "",
              price: "1€80"
            },
            {
              nom: "Brioche Michard",
              desc: "",
              price: "5€50"
            }
          ]
        },
        { 
          type: "Pour les fêtes",
          icon: "chikery-bk5",
          products : [
            {
              nom: "Stollen",
              desc: "",
              price: "9€"
            },
            {
              nom: "Pain d’ épices (100gr)",
              desc: "",
              price: "3€60"
            }
          ]
        },
        {
          type: "Traiteur",
          icon: "chikery-bk3",
          products : [
            {
              nom: "Quiche au choix",
              desc: "",
              price: "3€50"
            },
            {
              nom: "Plat au choix",
              desc: "",
              price: "8€00"
            },
            {
              nom: "Coupelle au choix",
              desc: "",
              price: "4€​"
            },
            {
              nom: "Soupe du jour",
              desc: "",
              price: "4€00"
            },
            {
              nom: "Jus du jour",
              desc: "",
              price: "2€80"
            }
          ]
        },
        { 
          type: "Boulangerie",
          icon: "chikery-bk1",
          products : [
            {
              nom: "Tradition (250g)",
              desc: "",
              price: "1€10"
            },
            {
              nom: "Tradi-graines (250gr)",
              desc: "",
              price: "1€30"
            },
            {
              nom: "Brun de plaisir (le kg)",
              desc: "",
              price: "9€00"
            },
            {
              nom: "Pain des Gaults (le kg)",
              desc: "",
              price: "8€00"
            },
            {
              nom: "Tour de main aux fruits (le kg)",
              desc: "",
              price: "12€00"
            },
            {
              nom: "Diététique (280gr)",
              desc: "",
              price: "2€80"
            }
          ]
        }
      ],
      active: { 
          type: "Patisserie",
          icon: "chikery-bk3",
          products : [
            {
              nom: "Buche Royal Michard",
              desc: "",
              price: "5€50"
            },
            {
              nom: "Dame Mac’",
              desc: "",
              price: "6€20"
            },
            {
              nom: "Mac’Michard",
              desc: "",
              price: "6€20"
            },
            {
              nom: "Mère Gourmande",
              desc: "",
              price: "4€50"
            },
            {
              nom: "Buches Voyage des îles",
              desc: "",
              price: "5€50"
            },
            {
              nom: "Paris-Pontoise",
              desc: "",
              price: "4€50"
            },
            {
              nom: "Miss cheese",
              desc: "",
              price: "4€50"
            },
            {
              nom: "Flan à l’ ancienne",
              desc: "",
              price: "2€80"
            }
          ]
        }
    }
  }
}
</script>

<style>
li{
  cursor: pointer;
}
</style>
